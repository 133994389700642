exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-js": () => import("./../../../src/templates/Case.js" /* webpackChunkName: "component---src-templates-case-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-remittance-es-js": () => import("./../../../src/templates/RemittanceES.js" /* webpackChunkName: "component---src-templates-remittance-es-js" */),
  "component---src-templates-report-template-1-js": () => import("./../../../src/templates/ReportTemplate1.js" /* webpackChunkName: "component---src-templates-report-template-1-js" */),
  "component---src-templates-report-template-2-js": () => import("./../../../src/templates/ReportTemplate2.js" /* webpackChunkName: "component---src-templates-report-template-2-js" */),
  "component---src-templates-report-template-3-js": () => import("./../../../src/templates/ReportTemplate3.js" /* webpackChunkName: "component---src-templates-report-template-3-js" */),
  "component---src-templates-rif-token-js": () => import("./../../../src/templates/RIFToken.js" /* webpackChunkName: "component---src-templates-rif-token-js" */),
  "component---src-templates-rif-wallet-js": () => import("./../../../src/templates/RIFWallet.js" /* webpackChunkName: "component---src-templates-rif-wallet-js" */),
  "component---src-templates-solutions-js": () => import("./../../../src/templates/Solutions.js" /* webpackChunkName: "component---src-templates-solutions-js" */),
  "component---src-templates-thank-you-js": () => import("./../../../src/templates/ThankYou.js" /* webpackChunkName: "component---src-templates-thank-you-js" */),
  "component---src-templates-use-cases-js": () => import("./../../../src/templates/UseCases.js" /* webpackChunkName: "component---src-templates-use-cases-js" */)
}

